<template>
  <div class="d-flex">
    <span class="p-float-label inline flex-grow-1">
      <AnimatedInput v-model="name.given" type="text" :label="i18n.$gettext('Firstname')" :disabled="readOnly" @blur="$emit('blur')" />
    </span>
    <span v-if="additional" class="p-float-label inline flex-grow-1" :class="{ 'mx-2': !isOnMobile, 'my-2': isOnMobile }">
      <Tags
          v-model="name.additional"
          multiple
          :get-autocomplete-items="() => []"
      />
      <label><translate>Middlenames</translate></label>
    </span>
    <span class="p-float-label inline flex-grow-1" :class="{ 'ml-2': !additional }">
      <AnimatedInput v-model="name.family" type="text" :label="i18n.$gettext('Lastname')" :disabled="readOnly" @blur="$emit('blur')" />
    </span>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import StructuredName from "@/model/common/carddav/StructuredName"
import breakpointUtil from "@/util/BreakpointUtil"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import {useGettext} from "@jshmrtn/vue3-gettext"
import Tags from "@/components/common/Tags.vue"

@Options({
  components: {AnimatedInput, Tags},
  //@ts-ignore
  props: {
    name: [StructuredName, Object],
    readOnly: {
      type: Boolean,
      default: false
    },
    additional: {
      type: Boolean,
      default: true
    }
  },
  emits: [ 'blur' ]
})
export default class StructuredNameField extends Vue {

  i18n = useGettext()

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }
}
</script>

<style scoped lang="scss">

</style>
